import React, { Component } from 'react';
import { Row, Col, Image, Button, Carousel } from 'antd';
import styles from  './index.module.scss'
import picture from '../../images/picture_home_02.png'
import homeone from '../../images/icon_home_01.png'
import hometwo from '../../images/icon_home_02.png'
import pictureblack_two from '../../images/picture_home_num02_black.png'
import pictureblack_three from '../../images/picture_home_num03_black.png'
import pictureblue_one from '../../images/picture_home_num01_blue.png'
import pictureblue_two from '../../images/picture_home_num02_blue.png'
import pictureblue_three from '../../images/picture_home_num03_blue.png'
import pictureblack_one from '../../images/picture_home_num01_black.png'
import picture_home_jxb from '../../images/picture_home_jxb.png'
import ai from '../../images/ai.png'
import picture_home_wtsb from '../../images/picture_home_wtsb.png'
import zhuanli1 from '../../images/zhuanli1.jpg'
import zhuanli2 from '../../images/zhuanli2.jpg'
import zhuanli3 from '../../images/zhuanli3.jpg'
import FooterPage from '../FooterPage/index';
import { withTranslation } from 'react-i18next';
import scjqr from '../../images/scjqr.png'
import T800 from '../../images/T800.jpg'
import jgsdjqr from '../../images/jgsdjqr.png'



class CoreTechnology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bnt: '了解更多>>',
            number: 0
        }
    }

    rowClick(row) {
        this.setState({ number: row });
    }

    onProductJump() {
        this.props.onProductJump();
    }

    handleNext(e) {
        // this.refs.welcome.prev();
        this.refs.welcome.innerSlider.slickGoTo(e)
    }
    
    render() {
        const { t } = this.props;
        const { number } = this.state;
        return (
            <Row className={styles.CompanyProfile} id="title">
                <Col span={24}>
                    <Row justify="center" style={{ paddingBottom: '70px' }}>
                        <span className={styles.title}>{t('code_7')}</span>
                    </Row>
                    {/* <Row style={{ paddingBottom: '70px' }} justify="center">
                        <span className={ styles.company}>Core technologies</span>
                    </Row> */}
                    <Row >
                        <Col span={14}>
                            <Row style={{ paddingBottom: '60px' }}>
                                <Col span={12} className={ styles.number }>
                                    <Image preview={false} src={ number === 0 ? pictureblue_one : pictureblack_one }/>
                                </Col>
                                <Col span={12 } onClick={()=> this.rowClick(0)}>
                                    <Row>
                                        <span className={number === 0 ? styles.contentTitle : styles.contentTitleone} onClick={() => this.handleNext(0)}>
                                        {t('code_26')}
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className={ styles.content}>
                                        {t('code_27')}
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: '60px' }}>
                                <Col span={12 } className={ styles.number }>
                                    <Image preview={false} src={ number === 1 ? pictureblue_two : pictureblack_two }/>
                                </Col>
                                <Col span={12} onClick={()=> this.rowClick(1)}>
                                    <Row>
                                        <span className={ number === 1 ? styles.contentTitle : styles.contentTitleone  } onClick={() => this.handleNext(1)}>
                                        {t('code_28')}
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className={ styles.content}>
                                        {t('code_29')}
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: '60px' }}>
                                <Col span={12} className={ styles.number }>
                                    <Image  preview={false} src={ number === 2 ? pictureblue_three : pictureblack_three }/>
                                </Col>
                                <Col span={12 } onClick={()=> this.rowClick(2)}>
                                    <Row>
                                        <span className={ number === 2 ? styles.contentTitle : styles.contentTitleone  } onClick={() => this.handleNext(2)}>
                                        {t('code_30')}
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className={ styles.content}>

                                        {t('code_31')}
                                        </span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className={styles.rightC}>
                                <Col>
                                    <Row>
                                        <Carousel style={{ width: '484px', height: '484px' }} autoplay={ false} ref="welcome">
                                            <div className={styles.contentStyle}>
                                                <Image preview={false} src={picture}></Image>
                                            </div>
                                            <div className={styles.contentStyle}>
                                                <Image preview={false} src={picture_home_wtsb}></Image>
                                            </div>
                                            <div className={styles.contentStyle}>
                                                <Image preview={false} src={picture_home_jxb}></Image>
                                            </div>
                                        </Carousel> 
                                    </Row>
                                </Col>
                                <Col style={{ paddingLeft: '30px' }}>
                                    <Row>
                                        <span className={styles.spanE}>SLAM</span>
                                    </Row>
                                    <Row>
                                        <span className={styles.spanE}>TECHNOLOGY</span>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ paddingTop: '140px', paddingBottom: '160px' }}>
                        <span className={styles.title}>{t('code_8')}</span>
                    </Row>
                    {/* <Row style={{ paddingBottom: '160px' }} justify="center">
                        <span className={ styles.company}>Products Show</span>
                    </Row> */}
                    <Row style={{ paddingBottom: '70px' }} justify="center">
                        {/* <Col style={{ lineHeight:'300px' }}>
                            <Image  preview={false}   src={homeone}>

                            </Image>
                        </Col> */}
                        <Col style={{ padding: '0 12px' }}>
                            <Row>
                                <Image  preview={false} src={ jgsdjqr } width={ 282} />
                            </Row>
                            <Row   preview={false} className={styles.listt}>
                            {t('code_32')}
                            </Row>
                            <Row  preview={false}  className={styles.listc}>
                            {t('code_33')}
                            </Row>
                        </Col>
                        <Col  style={{ padding: '0 12px' }}>
                            <Row>
                                <Image  preview={false} src={ scjqr } width={ 282} />
                            </Row>
                            <Row className={styles.listt}>
                            {t('code_34')}
                            </Row>
                            <Row  className={styles.listc}>
                            {t('code_35')}
                            </Row>
                        </Col>
                        <Col  style={{ padding: '0 12px' }}>
                            <Row>
                                <Image  preview={false}  src={ ai } width={ 282} />
                            </Row>
                            <Row  className={styles.listt}>
                            {t('code_36')}
                            </Row>
                            <Row  className={styles.listc}>
                            {t('code_37')}
                            </Row>
                        </Col>
                        <Col  style={{ padding: '0 12px' }}>
                            <Row>
                                <Image  preview={false}  src={ T800 } width={ 282} />
                            </Row>
                            <Row  className={styles.listt}>
                            {t('code_38')}
                            </Row>
                            <Row className={styles.listc}>
                            {t('code_39')}
                            </Row>
                        </Col>
                        {/* <Col style={{ lineHeight:'300px' }}>
                            <Image  preview={false}  src={ hometwo }/>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Button type="primary" shape="round" size='large' className={styles.btn} onClick={() => { this.onProductJump()}}>{t('code_40')}</Button>
                        </Col>
                    </Row>
                    <Row justify="center" style={{ paddingTop: '140px' }}>
                        <span className={ styles.title }>{t('code_9')}</span>
                    </Row>
                    <Row style={{ paddingBottom: '160px' }} justify="center">
                        <span className={ styles.company}>Invention patent</span>
                    </Row>
                    <Row style={{ paddingBottom: '160px' }} justify="center">
                        <Col style={{ padding: '0 13px' }}>
                            <Image preview={false} src={zhuanli1} width={178} height={256}></Image>

                        </Col>
                        <Col style={{ padding: '0 13px' }}>
                            <Image preview={false} src={zhuanli2} width={178} height={256}></Image>

                        </Col>
                        <Col style={{ padding: '0 13px' }}>
                            <Image preview={false} src={zhuanli3} width={178} height={256}></Image>

                        </Col>
                        <Col style={{ padding: '0 13px' }}>
                            <Image preview={false} src={zhuanli3} width={178} height={256}></Image>

                        </Col>
                        <Col style={{ padding: '0 13px' }}>
                            <Image preview={false} src={zhuanli3} width={178} height={256}></Image>

                        </Col>
                    </Row>
                    <FooterPage></FooterPage>
                </Col>
            </Row>
        )
    }
}
export default withTranslation()(CoreTechnology) ;
