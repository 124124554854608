import React, { Component } from 'react';
import { Layout, Row, Col, Image, Button } from 'antd';
import styles from  './index.module.scss'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import picture_about_banner from '../../images/picture_about_banner02.png'
import picture_about from '../../images/picture_about_01.png'
import FooterPage from '../FooterPage/index';
import picture_about_bg from '../../images/picture_about_bg.png'
import linlin from '../../images/linlin.png'
import picture_about_map from '../../images/picture_about_map.png'
import picture_abouts from '../../images/picture_about_02.png'
import picture_aboutt from '../../images/picture_about_03.png'
import fuhs from '../../images/fuhs.png'
import fuhaox from '../../images/fuhaox.png'
import jqr from '../../images/jqr.png'
import GPS from '../../images/gps.png'
class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bnt: '了解更多>>'
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Row className={styles.JoinUs}  justify="center" id="title">
                <Col span={24}>
                    <Row className={styles.jqrcontent}  justify="start">
                        <span className={styles.lin}></span>{t('code_6')}
                    </Row>
                    <Row style={{ paddingBottom: '140px' }}>
                        <Col className={styles.word} span={14} style={{ padding: '43px 120px 0 385px' }}>
                            <Row style={{ paddingBottom: '35px' }}>
                            {t('code_42')}

                            </Row>
                            <Row>
                            {t('code_43')}

                            </Row>
                        </Col>
                        <Col span={10}>
                            <Image preview={false} src={picture_about} width={768} height={485}></Image>
                        </Col>
                    </Row>
                    <Row style={{ backgroundImage: `url(${picture_about_bg})`, height: '938px', width: '100%' }}>
                        <Col span={24} style={{ paddingTop: '100px' }}>
                            <Row className={styles.jqrcontent}  justify="start" style={{ paddingBottom: '140px' }}>
                                <span className={styles.lin}></span> {t('code_180')}
                            </Row>
                            <Row justify="conter" style={{ padding: '0px 25%' }}>
                                <Col className={styles.con}>
                                    <Row className={styles.name}>
                                    {t('code_44')}
                                    </Row>
                                    <Row className={styles.zw}>
                                    {t('code_46')}
                                    </Row>
                                    <Row>
                                        <span className={styles.hengx}></span>
                                    </Row>
                                    <Row style={{ paddingBottom: '30px' }} className={styles.font}>
                                    {t('code_45')}

                                    </Row>
                                    <Row className={styles.font}>
                                    {t('code_47')}

                                    </Row>
                                </Col>
                                <Col className={styles.img}>
                                    <Image preview={false} src={linlin} width={326} height={388} ></Image>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row className={styles.jqrcontent} justify="start" style={{ marginBottom: '80px', marginTop: '140px' }}>
                                <span className={styles.lin}></span>{t('code_11')}
                            </Row>
                            <Row style={{ padding: '0 12% 100px 12%' }} justify="end">
                                <Col span={12}>
                                    <Image preview={false} src={picture_abouts}  height={385}></Image>

                                </Col>
                                <Col span={12}>
                                    <Row className={styles.nf} justify="center">
                                        <Image preview={false} src={fuhs} width={62} height={42}></Image>
                                    </Row>
                                    <Row className={styles.year} style={{ justifyContent: 'right' }}>2017</Row>
                                    <Row className={styles.spana}  style={{ justifyContent: 'right' }}><span>{t('code_48')}</span></Row>
                                    <Row className={styles.xuan} justify="center">{t('code_41')}</Row>
                                </Col>
                            </Row>
                            <Row  style={{ padding: '0 18% 140px 18%' }}>
                                <Col span={14} style={{ paddingTop: '50px'}}>
                                    <Row className={styles.list}>
                                        <Col><Image preview={false} src={jqr} width={32} height={32}></Image></Col>
                                        <Col className={styles.listspan}>{t('code_49')}</Col>
                                    </Row>
                                    <Row className={styles.list}>
                                        <Col><Image preview={false} src={jqr} width={32} height={32}></Image></Col>
                                        <Col className={styles.listspan}>{t('code_50')}</Col>
                                    </Row>
                                    <Row className={styles.list}>
                                        <Col><Image preview={false} src={jqr} width={32} height={32}></Image></Col>
                                        <Col className={styles.listspan}>{t('code_51')}</Col>
                                    </Row>
                                    <Row className={styles.list}>
                                        <Col><Image preview={false} src={jqr} width={32} height={32}></Image></Col>
                                        <Col className={styles.listspan}>{t('code_52')}</Col>
                                        
                                    </Row>
                                </Col>
                                <Col span={10}>
                                    <Row className={styles.nf}>
                                        <Image preview={false} src={fuhaox} width={62} height={42}></Image>

                                    </Row>
                                    <Row className={styles.nf} justify="center" style={{ paddingTop: '120px' }}>
                                        <Image preview={false} src={picture_aboutt} width={384} height={211}></Image>
                                    </Row>


                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Image preview={false} src={picture_about_banner} width={'100%'} height={261} ></Image>
                    </Row>
                    <Row style={{ padding: '140px 18% 100px 18%' }}>
                        <Col span={24}>
                            <Row>
                                <Row className={styles.jqrcontent} style={{ paddingLeft: '0' }}  justify="start">
                                    <span className={styles.lin}></span>{t('code_12')}
                                </Row>
                            </Row>
                            <Row className={styles.post} justify="end">
                                <Col>
                                    <Row style={{ borderBottom: '6px solid white', color: 'white', marginTop: '33px', width: '40px' }}></Row>
                                    <Row>contact</Row>
                                    <Row style={{ float: 'right' }}>us</Row>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '167px' }} >
                                <Image preview={false} src={picture_about_map} width={'100%'} height={600} ></Image>
                            </Row>

                            <Row className={styles.btlins}></Row>

                            <Row>
                                <Col span={23}>
                                    <Row>
                                        {t('code_175')}：
                                    </Row>
                                    <Row className={styles.bbspan}>
                                        {t('code_177')}
                                    </Row>
                                </Col>
                                <Col span={1}>
                                    <Image preview={false} src={GPS} width={26} height={30} ></Image>
                                </Col>
                            </Row>
                            <Row className={styles.btlin}></Row>
                            <Row>
                                {t('code_176')}：
                            </Row>
                            <Row className={styles.bbspan}>
                            Business@blackvision.net
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <FooterPage></FooterPage>
            </Row>
        )
    }
}
export default withTranslation()(AboutUs) ;
