import React, { Component } from 'react';
import { Layout, Row, Col, Tabs, Button, Image } from 'antd';
import styles from './HomePageCntent.module.scss'
import tab from '../data'
import { useTranslation, withTranslation } from 'react-i18next';
import banner from '../../images/picture_banner_01.png'
import probanner from '../../images/picture_about_banner.jpg'
import picture from '../../images/picture_text.png'
import CompanyProfile from '../CompanyProfile/index'
import CoreTechnology from '../CoreTechnology/index'
import ProductIntroduction from '../ProductIntroduction/index'
import classnames from 'classnames';
import picture_jion_banner from '../../images/picture_jion_banner.png'
import picture_Pro_banner from '../../images/picture_Pro_banner.png'


import ABOUTUS from '../../images/ABOUTUS.png'
import JoinUs from '../JoinUs/index'
import AboutUs from '../AboutUs/index'
import Talent from '../../images/JOIN US.png'
import productdescription from '../../images/PRODUCT.png'




const { Header, Content } = Layout;


function List(s) {
  const { t } = useTranslation();
  
}
class HomePageCntent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 3
    }
  }

  componentDidMount() {
    console.log(this.props.seconds)
    setTimeout(this.iTimer,0);

 }

  componentWillReceiveProps(next) {
    if (this.props.seconds !== next.seconds) {
      this.setState({ timer: 3 });
      setTimeout(this.iTimer,0);
    }
  }

  // 组件清除时清除定时器
  componentWillUnmount() {
    // clearTmeout(this.state.timer && this.state.timer);
  }

  iTimer = () => {
    setTimeout(() => {
      this.setState({
        timer: 0
      });
    }, 500)
  };

  onChange = (link) => {
    console.log('Anchor:OnChange', link);
  };

  onMouseLeave = () => {
    this.props.onMouseLeave();
  }

  setAnchor = (e) => {
    const { seconds } = this.props;
    if (seconds === 'one') {
      if (e === 0) {
        document.documentElement.scrollTop = (950 * 1)
      }
      if (e === 1) {
        document.documentElement.scrollTop = (1000 * 2)
      }
      if (e === 2) {
        document.documentElement.scrollTop = (1020 * 3)
      }
      if (e === 3) {
        document.documentElement.scrollTop = (970 * 4)
      }
    }
    if (seconds === 'two') {
      if (e === 0) {
        document.documentElement.scrollTop = (590 * 1)
      }
      if (e === 1) {
        document.documentElement.scrollTop = (700 * 2)
      }
      if (e === 2) {
        document.documentElement.scrollTop = (800 * 3)
      }
      if (e === 3) {
        document.documentElement.scrollTop = (970 * 4)
      }
    }
    if (seconds === 'three') {
      if (e === 0) {
        document.documentElement.scrollTop = (590 * 1)
      }
      if (e === 1) {
        document.documentElement.scrollTop = (900 * 2)
      }
      if (e === 2) {
        document.documentElement.scrollTop = (1050 * 3)
      }
      if (e === 3) {
        document.documentElement.scrollTop = (1060 * 4)
      }
    }
    if (seconds==='four') {
      if (e === 0) {
        document.documentElement.scrollTop = (590 * 1)
      }
      if (e === 1) {
        document.documentElement.scrollTop = (750 * 2)
      }
    }
  }

  render() {
    const { seconds, stateMouse, t } = this.props;
    const row = seconds === 'one' ? (
      <Row>
        <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(0) }}>{t("code_6")}</span></Col>
        <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(1) }}>{t("code_7")}</span></Col>
        <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(2) }}>{t("code_8")}</span></Col>
        <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(3) }}>{t("code_9")}</span></Col>
      </Row>
    ) :seconds === 'two' ? (
      <Row>
          <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(0) }}>{t("code_10")}</span></Col>
          <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(1) }}>{t("code_180")}</span></Col>
          <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(2) }}>{t("code_11")}</span></Col>
        <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(3) }}>{t("code_12")}</span></Col>
      </Row>
      ) : seconds === 'three' ? (
          <Row>
            <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(0) }}>{t("code_16")}</span></Col>
            <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(1) }}>{t("code_13")}</span></Col>
            <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(2) }}>{t("code_14")}</span></Col>
            <Col span={24 / 4}><span className={styles.cursor} onClick={() => { this.setAnchor(3) }}>{t("code_15")}</span></Col>
      </Row>
        ) : (
          <Row>
          <Col span={24 / 2}><span className={styles.cursor} onClick={() => { this.setAnchor(0) }}>{t("code_17")}</span></Col>
          <Col span={24 / 2}><span className={styles.cursor} onClick={() => { this.setAnchor(1) }}>{t("code_18")}</span></Col>
        </Row>
    )
    return (
      <Layout className={styles.HomePageCntent}>
        <Header style={{ display: stateMouse === 'Over' ? 'inline-block' : 'none' }} onMouseLeave={(a) => { this.onMouseLeave() }} className={styles.toplist}>
          {/* <List value={seconds} /> */}
          { row }
        </Header>
        {seconds === 'one' ? (
          <div>
            <Content>
              <div style={{ backgroundImage: `url(${banner})`, height: '900px', backgroundSize: "100% 100%" }}>
                <Row className={this.state.timer !== 3 ? styles.scrollone : styles.scroll} >
                  <div>
                    <Image height={208} width={900} preview={false} src={picture}></Image>
                  </div>
                </Row>
                <Row className={this.state.timer !== 3 ? styles.scrollalla : styles.scrollall}>
                  <span className={styles.showspan}>智能生活，从此刻开始</span>
                </Row>
              </div>
            </Content>
            <CompanyProfile></CompanyProfile>
            <CoreTechnology onProductJump={() => { this.props.onClick() }}></CoreTechnology>
          </div>
        ) :
          seconds === 'two' ? (
            <div>
              <Content>
                <div style={{ backgroundImage: `url(${probanner})`, height: '582px', backgroundSize: "100% 100%" }}>
                  <Row className={classnames(this.state.timer !== 3 ? styles.scrollalla : styles.scrollall)} style={{ justifyContent: 'center' }}>
                    {/* <span >product description</span> */}
                    <span className={styles.cpjs}>
                      <Image height={70} width={625} preview={false} src={ABOUTUS}></Image>
                    </span>
                  </Row>
                  <Row className={this.state.timer !== 3 ? styles.scrollalla : styles.scrollall}  style={{ justifyContent: 'center' }}>
                    <span className={styles.cpjsone}>团结 · 合作 · 创新 · 共赢</span>
                  </Row>
                </div>
              </Content>
              <AboutUs></AboutUs>
            </div>
          ) :  seconds === 'three' ? (
            <div>
            <Content>
              <div style={{ backgroundImage: `url(${picture_Pro_banner})`, height: '582px', backgroundSize: "100% 100%" }}>
                <Row className={classnames(this.state.timer !== 3 ? styles.scrollalla : styles.scrollall)} style={{ justifyContent: 'center' }}>
                      <span className={styles.cpjs}>
                        <Image height={70} preview={false} src={productdescription}></Image>
                      </span>
                      
                </Row>
                    <Row className={this.state.timer !== 3 ? styles.scrollalla : styles.scrollall} style={{ justifyContent: 'center' }}>
                  <span className={styles.cpjsone}>更智能 · 更生活</span>
                </Row>
                  </div>
              <ProductIntroduction></ProductIntroduction>

            </Content>
          </div>
            ) : (
              <div>
                <Content>
                  <div style={{ backgroundImage: `url(${picture_jion_banner})`, height: '582px', backgroundSize: "100% 100%" }}>
                    <Row className={classnames(this.state.timer !== 3 ? styles.scrollalla : styles.scrollall)} style={{ justifyContent: 'center' }}>
                      <span className={styles.cpjs}>
                        <Image height={70} preview={false} src={Talent}></Image>
                      </span>
                    </Row>
                    <Row className={this.state.timer !== 3 ? styles.scrollalla : styles.scrollall} style={{ justifyContent: 'center' }}>
                      <span className={styles.cpjsone}>精英聚首 · 多盘联动</span>
                    </Row>
                  </div>
                  
                </Content>
                <JoinUs>
                </JoinUs>
            </div>
          ) }
        
      </Layout>
    );
  }
}
export default withTranslation()(HomePageCntent);
