// import React, { Component, Suspense } from 'react';
import React, { Component } from 'react';

import { Layout, Row, Col, Tabs, Button, Image, Popover } from 'antd';
import styles from  './mystyle.module.scss'
import tab from './data'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import HomePageCntent from '../compontens/HomePageCntent/index'

import banner from '../images/Blackvision_logo.png'

const { Header, Footer, Content } = Layout;
const { TabPane } = Tabs;

// use hoc for class based components
class LegacyWelcomeClass extends Component {
    constructor(props) {
      super(props);
      this.state = {
        seconds: 'one',
        stateMouse: 'Leave',
        num: 0
      }
    }
    componentDidMount() {
      window.addEventListener('scroll', this.bindHandleScroll)
    }
    //在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount() {
        window.removeEventListener('scroll', this.bindHandleScroll);
    }
    bindHandleScroll = (e) => {
      const scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false)  || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
      if (scrollTop > 0 && scrollTop <= 800) {
        this.setState({ num: 0 });
      } else if (scrollTop > 800 && scrollTop <= 1600) {
        this.setState({ num: 1 });
      } else if (scrollTop > 1600 && scrollTop <= 2300) {
        this.setState({ num: 2 });
      } else if (scrollTop > 2300 && scrollTop <= 3000) {
        this.setState({ num: 3 });
      } else if( scrollTop > 3000) {
        this.setState({ num: 4 });
      }
    }
  onClick(e) {
    if (e==='five') {
      return;
    }
    document.documentElement.scrollTop = 0
    this.setState({ seconds: e === undefined ? 'three': e });
    }

  onMouseOver() {
      
      this.setState({ stateMouse: 'Over' });
    }

  onMouseLeave() {
    if (this.state.seconds==='five') {
      return;
    }
      this.setState({ stateMouse: 'Leave' });
    }
  
  setAnchor(e) {
    if (e === 0) {
      document.documentElement.scrollTop = (950 * 0)
    }
    if (e === 1) {
      document.documentElement.scrollTop = (950 * 1)
    }
    if (e === 2) {
      document.documentElement.scrollTop = (1000 * 2)

    }
    if (e === 3) {
      document.documentElement.scrollTop = (1020 * 3)
    }
    if (e===4) {
      document.documentElement.scrollTop = (970 * 4)
    }
    this.setState({ num: e });
  }
    render() {
      const { t } = this.props;
      const { stateMouse, seconds, num } = this.state;

      return (
        <Layout className={styles.Home}>
          {/* <Page/> */}
          <Header className={styles.Header} >
                <Row>
                {/* <Suspense fallback={<Loader/>}>
                    <MyComponent />
                </Suspense>                     */}
                    <Col  span={6}  style={{ paddingTop: '18px' }}>
              {/* 图标<Welcome/> */}
                      <Image height={50} width={200} preview={false} src={banner}></Image>
                    </Col>
                    <Col span={12}>
                      <Tabs defaultActiveKey={'three'} activeKey={seconds} className={styles.tabs}
                            onChange={(e) => { this.onClick(e) }}
                            onMouseOver={(a) => { this.onMouseOver() }}
                  // onMouseLeave={(a) => { this.onMouseLeave() }}
                >
                        <TabPane tab={t('code_1')} key='one' style={{ height: '100%' }}></TabPane>
                        <TabPane tab={t('code_2')} key="two" style={{ height: '100%' }}></TabPane>
                        <TabPane tab={t('code_3')} key="three" style={{ height: '100%' }}> </TabPane>
                        <TabPane tab={t('code_4')} key="four" style={{ height: '100%' }}></TabPane>
                        <TabPane tab={t('code_5')} key="five" style={{ height: '100%' }}></TabPane>
                      </Tabs>
                    </Col>
                    <Col span={6}>
                      <Page/>
                    </Col>
                </Row>
            </Header>
            <Content>
            <HomePageCntent
              seconds={seconds}
              stateMouse={stateMouse}
              onMouseLeave={() => { this.onMouseLeave() }}
              onClick={() => {this.onClick()}}
            ></HomePageCntent>
          </Content>
          <Row className={styles.anchorpoint} style={{ display: seconds === 'one' ? 'inline-block' : 'none' }}>
            <div className={num === 0 ? styles.anchorClick : styles.anchor} onClick={() => { this.setAnchor(0) }}></div>
            <Popover content={<div>{t('code_6')}</div>}>
              <div className={ num === 1 ? styles.anchorClick : styles.anchor } onClick={() => { this.setAnchor(1) } }></div>
            </Popover>
            <Popover content={<div>{t('code_7')}</div>}>
              <div className={ num === 2 ? styles.anchorClick : styles.anchor } onClick={() => { this.setAnchor(2) } }></div>
            </Popover>
            <Popover content={<div>{t('code_8')}</div>}>
              <div className={ num === 3 ? styles.anchorClick : styles.anchor } onClick={() => { this.setAnchor(3) } }></div>
            </Popover>
            <Popover content={<div>{t('code_9')}</div>}>
              <div className={num === 4 ? styles.anchorClick : styles.anchor} onClick={() => { this.setAnchor(4) }}></div>
            </Popover>
          </Row>
        </Layout>
        
      ) 
    }
}

const Welcome = withTranslation()(LegacyWelcomeClass);
  
  // Component using the Trans component
  // function MyComponent() {
  //   return (
  //     <Trans i18nKey="description.part1">
  //       To get started<code>src/App.js</code> and save to reload.
  //     </Trans>
  //   );
  // }


  // page uses the hook
function Page() {
    const { t, i18n } = useTranslation();
    React.translate = t;

  const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return (
      <span style={{  cursor:'pointer' }} >
        <Button type="button" onClick={() => {changeLanguage('de')}}>中</Button>
      <span className={ styles.span }></span>
        <Button type="button" onClick={() => {changeLanguage('en')}}>EN</Button>  
      </span>
    )
}

class Home extends Component {
  render() {
    return (
      <Welcome></Welcome>
      );
    }
  }
export default Home;