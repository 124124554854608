import React, { Component } from 'react';
import { Row, Col, Image, Breadcrumb } from 'antd';
import styles from  './index.module.scss'
import {  withTranslation } from 'react-i18next';
import FooterPage from '../FooterPage/index';
import picture_jion from '../../images/picture_jion_02.png'
import icon_up from '../../images/icon_up.png'
import icon_out from '../../images/icon-out.png'



class JoinUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bnt: '了解更多>>',
            upone: true,
            uptwo: false,
            upthree: false,
            upfour: false,
            upfive: false,
            upsix: false,
            upseven: false,
            upeight: false 
        }
    }

    getlistAll(e) {
        if (e === 'one') {
            this.setState({ upone: !this.state.upone });
        }
        if (e === 'two') {
            this.setState({ uptwo: !this.state.uptwo });
        }
        if (e === 'three') {
            this.setState({ upthree: !this.state.upthree });
        }
        if (e === 'four') {
            this.setState({ upfour: !this.state.upfour });
        }
        if (e === 'five') {
            this.setState({ upfive: !this.state.upfive });
        }
        if (e === 'six') {
            this.setState({ upsix: !this.state.upsix });
        }
        if (e === 'seven') {
            this.setState({ upseven: !this.state.upseven });
        }
        if (e === 'eight') {
            this.setState({ upeight: !this.state.upeight });
        }
    }

    render() {
        const { t } = this.props;
        const { upone, uptwo, upthree, upfour, upfive, upsix, upseven, upeight } = this.state;
        return (
            <Row className={styles.JoinUs} id="title">
                <Col span={24}>
                    <Row className={styles.jqrcontent}  justify="center">
                        {t('code_17')}？
                    </Row>
                    <Row style={{ textAlign: 'left', paddingBottom: '100px' }}>
                        <Col span={11} style={{ paddingLeft: '18%'}}>
                            <Row style={{ paddingBottom: '50px' }}>
                                <Col span={17}>
                                    <Row className={styles.jqr}>
                                    {t('code_66')}                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className={styles.title}>
                                    {t('code_67')}  
                                    </Row>
                                    <Row className={styles.word}>
                                    {t('code_68')}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className={styles.title}>
                                    {t('code_69')}
                                    </Row>
                                    <Row className={styles.word}>
                                    {t('code_70')}
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row className={styles.title}>
                                    {t('code_71')}
                                    </Row>
                                    <Row className={styles.word}>
                                    {t('code_72')}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={13}>
                            <Image src={picture_jion} preview={false}>
                            </Image>
                        </Col>
                    </Row>
                    <Row  style={{ backgroundColor: '#F8F8F9', padding: '140px 18%' }}>
                        <Col span={24}>
                            <Row className={styles.jqrcontent}  justify="center">
                            {t('code_74')}
                            </Row>
                            <Row className={styles.jqr} style={{ width: '100%', borderBottom: '3px solid #004CFF' }}>
                            {t('code_73')} 
                            </Row>
                            <Row style={{ paddingTop: '30px' }} justify="start">
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                            {t('code_75')} 
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                                <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        {/* <Breadcrumb.Item>3-5{t('code_179')}</Breadcrumb.Item> */}
                                                        <Breadcrumb.Item> {t('code_81')} </Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('one')}}>
                                                        <Image src={upone?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upone ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>Linux</span>
                                                <span className={ styles.jn }>C++</span>
                                                <span className={ styles.jn }>TCP/IP</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upone ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                        {t('code_93')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_76')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_77')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_78')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_79')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_80')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_100')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_82')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_83')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_84')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                            {t('code_87')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                                <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        {/* <Breadcrumb.Item>2{t('code_179')}</Breadcrumb.Item> */}
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('two')}}>
                                                        <Image src={uptwo?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ uptwo ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>Linux</span>
                                                <span className={ styles.jn }>C/C++</span>
                                                <span className={ styles.jn }>SLAM</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: uptwo ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_76')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_77')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_78')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_79')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_80')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                {t('code_92')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                            <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>2{t('code_179')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('three')}}>
                                                        <Image src={upthree?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upthree ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>JNI-NDK</span>
                                                <span className={ styles.jn }>C/C++</span>
                                                <span className={ styles.jn }>Android</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upthree ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_93')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_94')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_95')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_96')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_97')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_98')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_99')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_100')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_101')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_102')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_103')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_104')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_105')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_106')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                {t('code_107')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                            <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        {/* <Breadcrumb.Item>3-5年</Breadcrumb.Item> */}
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('four')}}>
                                                        <Image src={upfour?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upfour ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <span className={styles.jn}>Linux</span> */}
                                                <span className={ styles.jn }>PCB</span>
                                                <span className={ styles.jn }>NPI</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upfour ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_108')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_109')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_110')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_111')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_112')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_113')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_114')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_115')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_116')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_117')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                {t('code_114')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                            <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>2{t('code_179')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('five')}}>
                                                        <Image src={upfive?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upfive ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>CMake</span>
                                                <span className={ styles.jn }>C/C++</span>
                                                <span className={styles.jn}>Python</span>
                                                <span className={styles.jn}>GDB</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upfive ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_115')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_116')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_117')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_118')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_119')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_120')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_121')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_122')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_123')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_124')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                { t('code_125')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                            <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>2{t('code_179')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('six')}}>
                                                        <Image src={upsix?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upsix ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>JNI-NDK</span>
                                                <span className={ styles.jn }>JAVA</span>
                                                <span className={ styles.jn }>Android Framework</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upsix ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    { t('code_126')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_127')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_128')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_129')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_130')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_131')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_132')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_133')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_134')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_135')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_136')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_137')}
                                                    </Row>
                                                    <Row>
                                                    { t('code_138')}
                                                    </Row>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                {t('code_139')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                                <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        {/* <Breadcrumb.Item>3-5年</Breadcrumb.Item> */}
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('seven')}}>
                                                        <Image src={upseven?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upseven ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>Linux</span>
                                                <span className={ styles.jn }>C/C++</span>
                                                <span className={ styles.jn }>TCP/IP</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upseven ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_140')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_141')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_142')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_143')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_144')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_145')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_146')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_147')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_148')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_149')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_150')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_151')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_152')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_153')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row className={styles.zhaop}>
                                        <Col span={24}>
                                            <Row className={ styles.font}>
                                                {t('code_154')}
                                            </Row>
                                            <Row style={{ padding: '16px 0', textAlign: 'left' }}>
                                                <Col  span={4}>
                                                    <span className={ styles.my}>{t('code_174')}</span>
                                                </Col>
                                                <Col span={14}>
                                                    <Breadcrumb separator="|">
                                                        <Breadcrumb.Item>{t('code_178')}</Breadcrumb.Item>
                                                        {/* <Breadcrumb.Item>3-5{t('code_179')}</Breadcrumb.Item> */}
                                                        <Breadcrumb.Item>{t('code_81')}</Breadcrumb.Item>
                                                        <Breadcrumb.Item> </Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Col>
                                                <Col>
                                                    <Row className={styles.moreInformation} onClick={() => {this.getlistAll('eight')}}>
                                                        <Image src={upeight?icon_out:icon_up} preview={false} width={26} height={26} ></Image>
                                                        <span style={{ marginLeft: '8px' }}>{ upeight ? t('code_170') : t('code_171')}</span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <span className={styles.jn}>C++</span>
                                                <span className={ styles.jn }>linux</span>
                                                <span className={ styles.jn }>http</span>
                                            </Row>
                                            <Row className={styles.bt} style={{ display: upeight ? '': 'none' }}>
                                                <Col span={24}>
                                                    <Row>
                                                    {t('code_155')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_156')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_157')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_158')}
                                                    </Row>
                                                    <Row>
                                                    {t('code_159')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_160')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_161')} 
                                                    </Row>
                                                  
                                                    <Row>
                                                    {t('code_163')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_164')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_165')} 
                                                    </Row>
                                                    <Row>
                                                    {t('code_166')} 
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <FooterPage></FooterPage>
            </Row>
        )
    }
}
export default withTranslation()(JoinUs) ;
