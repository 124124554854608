import React, { Component } from 'react';
import { Layout, Row, Col, Image, Button } from 'antd';
import styles from  './index.module.scss'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import picture from '../../images/picture_home_01.png'

class CompanyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bnt: '了解更多>>'
        }
    }

    render() {
        const { t } = this.props;

        return (
            <Row className={styles.CompanyProfile} justify="center" id="title">
                <Col span={24}>
                    <Row style={{ paddingBottom: '70px' }}>
                        <span className={styles.title}>{t('code_6')}</span>
                    </Row>
                    {/* <Row style={{ paddingBottom: '70px' }} >
                        <span className={ styles.company}>company profiles</span>
                    </Row> */}
                    <Row className={ styles.divfont }>
                        {t('code_24') }{t('code_25')}
                    </Row>
                    {/* <Row className={ styles.divfont }>
                    司自2017年成立以来，发展迅速。研发的核心技术从最初的定位导航技术（SLAM），拓展到运
                    </Row>
                    <Row className={ styles.divfont }>
                    动控制，物体识别，三维建模，以及机械臂等众多领域。
                    </Row> */}
                    {/* <Row className={ styles.divfont }>
                        
                    </Row> */}
                    <Row style={{ paddingTop: '70px', paddingBottom: '50px' }}>
                        <Image preview={false} src={ picture }></Image>
                    </Row>
                    <Row justify="end">
                        <Col span={ 11 }></Col>
                        <Col span={ 13 }>
                            <Button type="primary" shape="round" size='large' className={ styles.btn}>{t('code_40')}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
export default withTranslation()(CompanyProfile) ;
