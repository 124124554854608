import React, { Component } from 'react';
import { Layout, Row, Col, Image, Button } from 'antd';
import styles from  './index.module.scss'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import picture_Pro_APP from '../../images/picture_Pro_APP.png'
import picture_Pro_banner from '../../images/picture_Pro_banner02.png'
import FooterPage from '../FooterPage/index';
import picture_Pro from '../../images/picture_Pro_02.png'
import picture_Prot from '../../images/picture_Pro_03.png'
import picture_Pro_robot from '../../images/picture_Pro_robot.png'
import T800 from '../../images/T800.jpg'
import V302 from '../../images/V302.jpg'
import X500 from '../../images/X500.jpg'
import X580 from '../../images/X580.jpg'
import X700 from '../../images/X700.jpg'
import X900 from '../../images/X900.jpg'
import jgsdjqr from '../../images/jgsdjqr.png'
import ai from '../../images/ai.png'





class ProductIntroduction extends Component {

    render() {
        const { t } = this.props;

        return (
            <Row className={styles.ProductIntroduction} justify="center" id="title">
                <Row>
                    <Col>
                        
                        <Row className={styles.title}>
                        Libos APP
                        </Row>
                        <Row className={styles.sheng}>
                            {t('code_54')}
                        </Row>
                        <Row style={{ paddingTop: '187px'}}>
                            <span className={styles.word} style={{ paddingBottom: '20px' }}>
                                {t('code_55')}
                            </span>
                        </Row>
                        <Row style={{ padding: '0px 150px 90px 0'}}>
                            <span className={styles.word}>
                                {t('code_56')}
                            </span>
                        </Row>
                        <Row style={{ paddingLeft: '0' }}>
                            <Button type="primary" shape="round" size='large' className={styles.btn} onClick={() => {window.location.href="https://admin.heimouyun.com/security/src/download.html" }}>{t('code_57')}</Button>
                        </Row>
                    </Col>
                    <Col>
                        <Image preview={false} width={541} height={ 631} src={ picture_Pro_APP }></Image>
                    </Col>
                </Row>
                <Row style={{ backgroundImage: `url(${picture_Pro_banner})`, backgroundSize: '100% 100%', width: '100%', height: '260px', marginBottom: '140px', marginTop:'150px' }}>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col span={24}>
                        <Row style={{ paddingLeft: '15%', paddingBottom: '90px' }}>
                            <span className={styles.top}>{t('code_58')}</span>
                        </Row>
                        <Row style={{ paddingLeft: '15%' }}>
                            <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ T800 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                T800
                                {/* {t('code_59')} */}
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ V302 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                V302
                                {/* {t('code_60')} */}
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ X500 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                X500
                                {/* {t('code_61')} */}
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ paddingLeft: '15%' }}>
                        <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ X580 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                X580
                                {/* {t('code_59')} */}
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ X700 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                X700
                                {/* {t('code_60')} */}
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Image preview={false} width={360} height={ 360} src={ X900 }></Image>
                                </Row>
                                <Row className={styles.jqra}>
                                X900
                                {/* {t('code_61')} */}
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ backgroundImage: `url(${picture_Pro})`, backgroundSize: '100% 100%', width: '100%', height: '1076px', marginBottom: '140px', marginTop: '150px' }}>
                            <Col style={{ paddingTop : '310px', paddingLeft: '360px' }}>
                                <Row className={ styles.jqr }>
                                {t('code_34')}
                                </Row>
                                <Row className={ styles.jqrcontent}>
                                {t('code_62')}
                                </Row>
                                <Row>
                                    <Button type="primary" shape="round" size='large' className={styles.btn}>{t('code_40')}</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '140px', paddingRight: '142px' }}>
                        <Image preview={false} src={picture_Prot} width={690} height={447}></Image>
                    </Col>
                    <Col>
                        <Row className={styles.jqr} style={{ letterSpacing: 0 }}>
                        {t('code_63')}
                        </Row>
                        <Row className={styles.gcj} style={{ paddingTop: '30px', justifyContent: 'right' }} >
                            <span>{t('code_64')}</span>
                        </Row>
                        <Row className={styles.gcj} justify='end' style={{ paddingBottom: '90px' }}>
                        {t('code_65')}
                        </Row>
                        <Row  justify='end'>
                            <Button type="primary" shape="round" size='large' className={styles.btn}>{t('code_40')}</Button>
                        </Row>
                        
                    </Col>
                </Row>
                <FooterPage></FooterPage>
            </Row>
        )
    }
}
export default withTranslation()(ProductIntroduction) ;

